import React, { useEffect, useState } from 'react';
import { BsFillFuelPumpDieselFill } from "react-icons/bs";
import { IoMdSpeedometer } from "react-icons/io";
import { GiWeight } from "react-icons/gi";
import { TbEngine } from "react-icons/tb";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { PiPhoneCallFill } from "react-icons/pi";
import InquiryModal from '../../Components/Modals/InquiryModal';

const BikeDetails = () => {
    const params = useParams();
    const [activeImage, setActiveImage] = useState(0);
    const navigate = useNavigate();
    const [bikesAllData, setBikesAllData] = useState({});
    const [bikeImages, setBikeImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleImageChange = (index) => {
        setActiveImage(index);
    };

    useEffect(() => {
        singleBikeDetails();
    }, []);

    const singleBikeDetails = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://archita-motors.onrender.com/archita-motors-api/bikes/get-bike-by-id/${params?.id}`,
            headers: {},
            data: ''
        };

        setIsLoading(true);
        axios.request(config)
            .then((response) => {
                setIsLoading(false);
                setBikesAllData(response?.data);
                setBikeImages(response?.data?.primaryDetails?.bikeImages);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    };

    if (isLoading) {
        return (
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 my-6">
                {/* Loading Skeleton */}
                <div className="flex flex-col md:flex-row">
                    {/* Main Image */}
                    <div className="flex-1 mb-4 md:mb-0 md:mr-4">
                        <div className="relative w-full md:h-[40rem] h-48 bg-gray-200 animate-pulse rounded-lg"></div>
                    </div>

                    {/* Thumbnail Images */}
                    <div className="flex md:flex-col md:space-y-4 space-x-4 md:space-x-0 overflow-x-auto md:overflow-hidden">
                        {[...Array(4)].map((_, index) => (
                            <div
                                key={index}
                                className="w-24 h-16 bg-gray-200 animate-pulse rounded-lg shadow-md"
                            />
                        ))}
                    </div>
                </div>

                {/* Bike Details */}
                <div className="mt-8">
                    <div className='block md:flex justify-between items-center'>
                        <div className="w-1/3 h-6 bg-gray-200 animate-pulse rounded"></div>
                        <div className="mt-2 md:mt-0 w-1/4 h-8 bg-gray-200 animate-pulse rounded"></div>
                    </div>

                    <p className="text-[#D01818] text-lg sm:text-xl font-semibold mt-2 bg-gray-200 animate-pulse h-6 rounded"></p>

                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                        {[...Array(4)].map((_, index) => (
                            <div key={index} className="flex items-center space-x-2">
                                <div className="w-6 h-6 bg-gray-200 animate-pulse rounded"></div>
                                <div className="flex flex-col">
                                    <div className="w-16 h-4 bg-gray-200 animate-pulse rounded"></div>
                                    <div className="w-24 h-4 bg-gray-200 animate-pulse rounded mt-1"></div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="mt-6">
                        <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-[#0D1637] bg-gray-200 animate-pulse h-6 rounded"></h2>
                        <p className="mt-2 text-[#333] text-sm sm:text-base bg-gray-200 animate-pulse h-16 rounded"></p>
                    </div>

                    <div className="mt-6">
                        <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-[#0D1637] bg-gray-200 animate-pulse h-6 rounded"></h2>
                        <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            {[...Array(4)].map((_, index) => (
                                <div key={index} className="flex flex-col">
                                    <div className="w-32 h-4 bg-gray-200 animate-pulse rounded"></div>
                                    <div className="w-24 h-4 bg-gray-200 animate-pulse rounded mt-1"></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <InquiryModal
                isOpen={isModalOpen}
                onClose={closeModal}
            />
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 my-6">
                <div className="flex justify-between items-center mb-6">
                    <button
                        onClick={() => navigate('/products')}
                        className="flex items-center text-gray-800"
                    >
                        <FaArrowLeft size={20} />
                        <span className="ml-2 text-md tracking-wide font-bold">Back to All Bikes</span>
                    </button>

                    <div className="hidden md:flex items-center text-base font-medium">
                        <span>
                            <PiPhoneCallFill size={24} className='mr-2 text-[#D01818]' />
                        </span>
                        <span className="text-[#D01818] font-bold text-lg">+91-7978272696</span>
                    </div>
                </div>

                {/* Bike Images */}
                <div className="flex flex-col md:flex-row">
                    {/* Main Image */}
                    <div className="flex-1 mb-4 md:mb-0 md:mr-4">
                        <div className="relative w-full md:h-[40rem] h-48">
                            <img
                                src={bikeImages && bikeImages[activeImage]}
                                alt="Bike"
                                className="absolute inset-0 w-full h-full md:object-cover object-contain rounded-lg"
                            />
                        </div>
                    </div>

                    {/* Thumbnail Images */}
                    <div className="flex md:flex-col md:space-y-4 space-x-4 md:space-x-0 overflow-x-auto md:overflow-hidden">
                        {bikeImages?.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Thumbnail ${index + 1}`}
                                className={`w-24 h-16 object-cover cursor-pointer rounded-lg shadow-md transition-transform duration-300 ${activeImage === index ? 'border-2 border-[#D01818]' : ''
                                    }`}
                                onClick={() => handleImageChange(index)}
                            />
                        ))}
                    </div>
                </div>

                {/* Bike Details */}
                <div className="mt-8">
                    <div className='block md:flex justify-between items-center'>
                        <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-[#0D1637]">
                            {bikesAllData?.primaryDetails?.makeAndModel}
                        </h1>

                        <button
                            onClick={openModal}
                            className="mt-2 md:mt-0 tracking-wide whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#1a1a26] hover:bg-[#810000] transition-transform duration-300 ease-in-out">
                            Inquiry For Bike
                        </button>
                    </div>

                    <p className="text-[#D01818] text-lg sm:text-xl font-semibold mt-2">
                        ₹&nbsp;{bikesAllData?.primaryDetails?.secondSellingPrice}
                    </p>

                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                        <div className="flex items-center space-x-2">
                            <BsFillFuelPumpDieselFill size={20} color='#810000' />
                            <div>
                                <div className="text-xs sm:text-sm text-[#A2A4A8]">Fuel Type</div>
                                <div className="text-sm sm:text-lg text-[#0D1637]">
                                    {bikesAllData?.primaryDetails?.fuelType}
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center space-x-2">
                            <IoMdSpeedometer size={20} color='#810000' />
                            <div>
                                <div className="text-xs sm:text-sm text-[#A2A4A8]">Mileage</div>
                                <div className="text-sm sm:text-lg text-[#0D1637]">
                                    30 Km
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center space-x-2">
                            <GiWeight size={20} color='#810000' />
                            <div>
                                <div className="text-xs sm:text-sm text-[#A2A4A8]">Weight</div>
                                <div className="text-sm sm:text-lg text-[#0D1637]">
                                    110 Kg
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center space-x-2">
                            <TbEngine size={20} color='#810000' />
                            <div>
                                <div className="text-xs sm:text-sm text-[#A2A4A8]">Engine</div>
                                <div className="text-sm sm:text-lg text-[#0D1637]">
                                    {bikesAllData?.primaryDetails?.engineDisplay}&nbsp;CC
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6">
                        <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-[#0D1637]">
                            Description
                        </h2>
                        <p className="mt-2 text-[#333] text-sm sm:text-base">
                            {bikesAllData?.primaryDetails?.extraDescription}
                        </p>
                    </div>

                    <div className="mt-6">
                        <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-[#0D1637]">
                            Additional Details
                        </h2>
                        <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            {Object.entries({
                                "Original Purchase Date": bikesAllData?.primaryDetails?.originalPurchaseDate?.substring(0, 10),
                                "Status": bikesAllData?.primaryDetails?.bikeStatus,
                                "Transmission Type": bikesAllData?.primaryDetails?.transmissionType,
                                "Colour": bikesAllData?.primaryDetails?.color,
                                "Ground Clearance": bikesAllData?.primaryDetails?.groundClearance,
                                "Tyre Type": bikesAllData?.primaryDetails?.tyreType,
                                "Wheels Type": bikesAllData?.primaryDetails?.wheelsType,
                                "Odometer Reading": bikesAllData?.primaryDetails?.odometerReading,
                            }).map(([label, value]) => (
                                <div key={label} className="flex flex-col">
                                    <span className="text-xs sm:text-sm text-[#A2A4A8]">{label}:</span>
                                    <span className="text-sm sm:text-base text-[#0D1637]">{value}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default BikeDetails;
















// import React, { useEffect, useState } from 'react';
// import { BsFillFuelPumpDieselFill } from "react-icons/bs";
// import { IoMdSpeedometer } from "react-icons/io";
// import { GiWeight } from "react-icons/gi";
// import { TbEngine } from "react-icons/tb";
// import { FaArrowLeft } from "react-icons/fa";
// import { useNavigate, useParams } from 'react-router-dom';
// import axios from 'axios';
// import { PiPhoneCallFill } from "react-icons/pi";
// import InquiryModal from '../../Components/Modals/InquiryModal';

// const BikeDetails = () => {
//     const params = useParams();
//     const [activeImage, setActiveImage] = useState(0);
//     const navigate = useNavigate();
//     const [bikesAllData, setBikesAllData] = useState({});
//     const [bikeImages, setBikeImages] = useState([]);
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);

//     const openModal = () => setIsModalOpen(true);
//     const closeModal = () => setIsModalOpen(false);

//     const handleImageChange = (index) => {
//         setActiveImage(index);
//     };

//     useEffect(() => {
//         singleBikeDetails();
//     }, []);

//     const singleBikeDetails = () => {
//         let config = {
//             method: 'get',
//             maxBodyLength: Infinity,
//             url: `https://archita-motors.onrender.com/archita-motors-api/bikes/get-bike-by-id/${params?.id}`,
//             headers: {},
//             data: ''
//         };

//         setIsLoading(true);
//         axios.request(config)
//             .then((response) => {
//                 setIsLoading(false);
//                 setBikesAllData(response?.data);
//                 setBikeImages(response?.data?.primaryDetails?.bikeImages);
//             })
//             .catch((error) => {
//                 setIsLoading(false);
//                 console.log(error);
//             });
//     };

//     return (
//         <React.Fragment>
//             <InquiryModal
//                 isOpen={isModalOpen}
//                 onClose={closeModal}
//             />
//             <div className="container mx-auto px-4 sm:px-6 lg:px-8 my-6">
//                 <div className="flex justify-between items-center mb-6">
//                     <button
//                         onClick={() => navigate('/products')}
//                         className="flex items-center text-gray-800"
//                     >
//                         <FaArrowLeft size={20} />
//                         <span className="ml-2 text-md tracking-wide font-bold">Back to All Bikes</span>
//                     </button>

//                     <div className="hidden md:flex items-center text-base font-medium">
//                         <span>
//                             <PiPhoneCallFill size={24} className='mr-2 text-[#D01818]' />
//                         </span>
//                         <span className="text-[#D01818] font-bold text-lg">+91-7978272696</span>
//                     </div>
//                 </div>

//                 {/* Bike Images */}
//                 <div className="flex flex-col md:flex-row">
//                     {/* Main Image */}
//                     <div className="flex-1 mb-4 md:mb-0 md:mr-4">
//                         <div className="relative w-full md:h-[40rem] h-48">
//                             <img
//                                 src={bikeImages && bikeImages[activeImage]}
//                                 alt="Bike"
//                                 className="absolute inset-0 w-full h-full md:object-cover object-contain rounded-lg"
//                             />
//                         </div>
//                     </div>

//                     {/* Thumbnail Images */}
//                     <div className="flex md:flex-col md:space-y-4 space-x-4 md:space-x-0 overflow-x-auto md:overflow-hidden">
//                         {bikeImages?.map((image, index) => (
//                             <img
//                                 key={index}
//                                 src={image}
//                                 alt={`Thumbnail ${index + 1}`}
//                                 className={`w-24 h-16 object-cover cursor-pointer rounded-lg shadow-md transition-transform duration-300 ${activeImage === index ? 'border-2 border-[#D01818]' : ''
//                                     }`}
//                                 onClick={() => handleImageChange(index)}
//                             />
//                         ))}
//                     </div>
//                 </div>

//                 {/* Bike Details */}
//                 <div className="mt-8">
//                     <div className='block md:flex justify-between items-center'>
//                         <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-[#0D1637]">
//                             {bikesAllData?.primaryDetails?.makeAndModel}
//                         </h1>

//                         <button
//                             onClick={openModal}
//                             className="mt-2 md:mt-0 tracking-wide whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#1a1a26] hover:bg-[#810000] transition-transform duration-300 ease-in-out">
//                             Inquiry For Bike
//                         </button>
//                     </div>

//                     <p className="text-[#D01818] text-lg sm:text-xl font-semibold mt-2">
//                         ₹&nbsp;{bikesAllData?.primaryDetails?.secondSellingPrice}
//                     </p>

//                     <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
//                         <div className="flex items-center space-x-2">
//                             <BsFillFuelPumpDieselFill size={20} color='#810000' />
//                             <div>
//                                 <div className="text-xs sm:text-sm text-[#A2A4A8]">Fuel Type</div>
//                                 <div className="text-sm sm:text-lg text-[#0D1637]">
//                                     {bikesAllData?.primaryDetails?.fuelType}
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="flex items-center space-x-2">
//                             <IoMdSpeedometer size={20} color='#810000' />
//                             <div>
//                                 <div className="text-xs sm:text-sm text-[#A2A4A8]">Mileage</div>
//                                 <div className="text-sm sm:text-lg text-[#0D1637]">
//                                     30 Km
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="flex items-center space-x-2">
//                             <GiWeight size={20} color='#810000' />
//                             <div>
//                                 <div className="text-xs sm:text-sm text-[#A2A4A8]">Weight</div>
//                                 <div className="text-sm sm:text-lg text-[#0D1637]">
//                                     110 Kg
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="flex items-center space-x-2">
//                             <TbEngine size={20} color='#810000' />
//                             <div>
//                                 <div className="text-xs sm:text-sm text-[#A2A4A8]">Engine</div>
//                                 <div className="text-sm sm:text-lg text-[#0D1637]">
//                                     {bikesAllData?.primaryDetails?.engineDisplay}&nbsp;CC
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="mt-6">
//                         <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-[#0D1637]">
//                             Description
//                         </h2>
//                         <p className="mt-2 text-[#333] text-sm sm:text-base">
//                             {bikesAllData?.primaryDetails?.extraDescription}
//                         </p>
//                     </div>

//                     <div className="mt-6">
//                         <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-[#0D1637]">
//                             Additional Details
//                         </h2>
//                         <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
//                             {Object.entries({
//                                 "Original Purchase Date": bikesAllData?.primaryDetails?.originalPurchaseDate?.substring(0, 10),
//                                 "Status": bikesAllData?.primaryDetails?.bikeStatus,
//                                 "Transmission Type": bikesAllData?.primaryDetails?.transmissionType,
//                                 "Colour": bikesAllData?.primaryDetails?.color,
//                                 "Ground Clearance": bikesAllData?.primaryDetails?.groundClearance,
//                                 "Tyre Type": bikesAllData?.primaryDetails?.tyreType,
//                                 "Wheels Type": bikesAllData?.primaryDetails?.wheelsType,
//                                 "Odometer Reading": bikesAllData?.primaryDetails?.odometerReading,
//                             }).map(([label, value]) => (
//                                 <div key={label} className="flex flex-col">
//                                     <span className="text-xs sm:text-sm text-[#A2A4A8]">{label}:</span>
//                                     <span className="text-sm sm:text-base text-[#0D1637]">{value}</span>
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </React.Fragment>
//     );
// };

// export default BikeDetails;