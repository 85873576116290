import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import MainNav from "./MainNav";
import { PiPhoneCallFill } from "react-icons/pi";

const Navbar = () => {
  return (
    <React.Fragment>
      <div className="bg-[#810000] text-white">
        <div className="w-full px-6 md:px-10 hidden md:block">
          <div className="flex justify-between items-center py-3">
            {/* Phone number on the left */}
            <div className="flex items-center text-base font-medium">
              <span>
                <PiPhoneCallFill size={20} className="mr-2 text-gray-300" />
              </span>
              <span className="text-gray-300">
              +91-7978272696, 9861484493, 8638123269
              </span>
            </div>
            {/* Social media icons on the right */}
            <div className="flex space-x-4">
              <a href="#" className="text-gray-300 hover:text-white">
                <FaFacebookF />
              </a>
              <a href="#" className="text-gray-300 hover:text-white">
                <FaTwitter />
              </a>
              <a href="#" className="text-gray-300 hover:text-white">
                <FaInstagram />
              </a>
              <a href="#" className="text-gray-300 hover:text-white">
                <FaLinkedinIn />
              </a>
            </div>
          </div>
        </div>
      </div>

      <MainNav />
    </React.Fragment>
  );
};

export default Navbar;

// import React from 'react'

// const Navbar = () => {
//   return (
//     <nav className="bg-gray-800 text-white">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="flex justify-between items-center py-3 md:justify-start md:space-x-10">
//           <div className="flex justify-start lg:w-0 lg:flex-1">
//             <a href="#">
//               <span className="sr-only">Your Company</span>
//             </a>
//           </div>
//           <div className="hidden md:flex space-x-10">
//             <a href="#" className="text-base font-medium text-gray-300 hover:text-white">Home</a>
//             <a href="#" className="text-base font-medium text-gray-300 hover:text-white">About</a>
//             <a href="#" className="text-base font-medium text-gray-300 hover:text-white">Services</a>
//             <a href="#" className="text-base font-medium text-gray-300 hover:text-white">Contact</a>
//           </div>
//         </div>
//       </div>
//     </nav>
//   )
// }

// export default Navbar
