import React from 'react';
import HAboutImg from "../../Assets/HAboutImg.png"

const HomeAbout = () => {
    return (
        <div className="px-6 md:px-10 py-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                    <div className='flex justify-start'>
                        <span className='uppercase text-[#D01818] text-xl font-semibold tracking-wide border-b-2 border-dotted border-[#D01818]'>
                            About us
                        </span>
                    </div>
                    <div className='text-start text-3xl md:text-4xl font-extrabold tracking-wider my-4 text-[#0D1637]'>
                        About Our Showroom
                    </div>
                    <p className="text-gray-600 text-lg tracking-wide text-justify">
                        Welcome to Our Showroom, where you'll find a wide range of quality pre-owned motorcycles at competitive prices. Our showroom features an extensive selection of bikes, including sport bikes, cruisers, and more, all rigorously inspected to ensure top performance and safety.
                        <br /><br />
                        Our friendly and knowledgeable staff is dedicated to helping you find the perfect bike to suit your style and budget. We offer a seamless buying experience with transparent pricing and flexible financing options.
                    </p>
                </div>

                <div>
                    <img
                        src='https://www.shutterstock.com/image-photo/helsinki-finlandcirca-apr-2018-new-600nw-1664157934.jpg'
                        alt="Sample"
                        className="w-full h-auto rounded-lg shadow-md"
                    />
                </div>
            </div>
        </div>
    );
};

export default HomeAbout;
