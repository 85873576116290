import React, { useEffect, useState } from 'react';
import img1 from '../../Assets/car.png';
import img2 from '../../Assets/rating.png';
import img3 from '../../Assets/trophy.png';
import img4 from '../../Assets/showroom.png';

const sections = [
  {
    id: 1,
    icon: img1,
    number: 150,
    description: 'Vehicles in Stock',
  },
  {
    id: 2,
    icon: img2,
    number: 230,
    description: 'Satisfied Customers',
  },
  {
    id: 3,
    icon: img3,
    number: 9,
    description: 'Award Achievements',
  },
  {
    id: 4,
    icon: img4,
    number: 3,
    description: 'Total Showrooms',
  },
];

// Custom hook to animate number counting
const useCounter = (end, duration) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const increment = end / (duration * 1000 / 16);

    const timer = setInterval(() => {
      start += increment;
      if (start > end) {
        clearInterval(timer);
        setCount(end);
      } else {
        setCount(Math.ceil(start));
      }
    }, 16);

    return () => clearInterval(timer);
  }, [end, duration]);

  return count;
};

const Section = ({ section }) => {
  const count = useCounter(section.number, 2); // 2 seconds duration

  return (
    <div className="flex flex-col items-center p-6 text-center hover:shadow-md hover:bg-gray-100 rounded-lg">
      <img
        src={section.icon}
        alt={section.icon}
        className="h-16 w-auto object-contain mb-4"
      />

      <h3 className="text-3xl font-bold text-gray-800 my-2">
        {count}
      </h3>
      <p className="text-gray-600 font-semibold tracking-wide text-lg">
        {section.description}
      </p>
    </div>
  );
};

const Counter = () => (
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
    {sections.map((section) => (
      <Section key={section.id} section={section} />
    ))}
  </div>
);

export default Counter;
