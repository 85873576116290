import React from "react";
import PageHeader from "../../Components/Layout/PageHeader";
import img1 from "../../Assets/bike_about_us.jpg";
import Counter from "../../Components/Home/Counters";
import Branches from "../../Components/About/Branches";
import imgScooter from "../../Assets/img_about_us.jpg";
const About = () => {
  return (
    <React.Fragment>
      <PageHeader pageName="About Us" />
      <div className="flex flex-col items-center px-6 md:px-10 py-12 space-y-12">
        {/* 1st Section: Experience */}
        <section className="flex flex-col md:flex-row items-center space-y-8 md:space-y-0 md:space-x-12">
          <div className="md:w-1/2 flex justify-center md:justify-start">
            <img
              src={img1}
              alt="Experience"
              className="w-[90%] h-auto object-cover rounded-lg shadow-md"
            />
          </div>
          <div className="md:w-1/2">
            <div className="flex justify-start mb-6">
              <span className="uppercase text-[#D01818] text-xl font-semibold tracking-wide border-b-2 border-dotted border-[#D01818]">
                About Us
              </span>
            </div>
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              We have over 20 years of Experience
            </h2>
            <p className="text-lg text-gray-700 mb-6 text-justify">
              With over 20 years of experience in the automotive industry, our
              showroom has served thousands of satisfied customers. Our journey
              has been marked by a commitment to quality, customer satisfaction,
              and continuous innovation. We pride ourselves on offering a
              diverse range of vehicles to meet the unique needs of every
              client, from first-time buyers to seasoned collectors. Our
              dedicated team of professionals is always on hand to provide
              personalized service, ensuring that every customer leaves with the
              perfect vehicle for their lifestyle.
            </p>

            {/* Two Columns of Points with Check Icons */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <ul className="space-y-4">
                <li className="flex items-center">
                  <span className="text-green-500 mr-3">✔️</span>
                  High-Quality Vehicles
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-3">✔️</span>
                  Excellent Customer Service
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-3">✔️</span>
                  Wide Range of Options
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-3">✔️</span>
                  Affordable Pricing
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-3">✔️</span>
                  Trusted by Thousands
                </li>
              </ul>
              <ul className="space-y-4">
                <li className="flex items-center">
                  <span className="text-green-500 mr-3">✔️</span>
                  Professional Staff
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-3">✔️</span>
                  Easy Financing Options
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-3">✔️</span>
                  Comprehensive Warranty
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-3">✔️</span>
                  Convenient Location
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-3">✔️</span>
                  Strong Community Presence
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* 2nd Section: Showroom Description */}
        <section className="text-center space-y-4">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            About Our Showroom
          </h2>
          <p className="text-lg text-gray-700 mx-auto tracking-wide">
            Founded in 2004, we have been at the forefront of providing
            high-quality motorbikes to passionate riders and offering a
            hassle-free platform for both selling and purchasing bikes. Whether
            you're looking for a sleek, high-performance model or a reliable
            everyday ride, we have a wide range of options to suit every need
            and budget.{" "}
          </p>
          <p className="text-lg text-gray-700 mx-auto tracking-wide">
            At our core, we believe in making the process of buying and selling
            motorbikes smooth and transparent. For those looking to sell, we
            offer a fair evaluation process that ensures you get the best value
            for your bike.
          </p>
          <p className="text-lg text-gray-700 mx-auto tracking-wide">
            For buyers, we guarantee a wide selection of well-maintained
            motorbikes, thoroughly inspected for quality and safety. With over a
            decade of experience in the industry, we are proud to have built a
            reputation for trust, reliability, and customer satisfaction.
            Whether you're a seasoned rider or a newcomer, we are here to help
            you find the perfect motorbike to meet your needs.
          </p>
        </section>

        <Counter />

        {/* 3rd Section: Best Dealers */}
        <section className="w-full ">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">
            Our Best Dealers
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="flex flex-col items-center text-center p-6 border border-gray-200 rounded-lg shadow-md">
              <img
                src="https://cdni.autocarindia.com/ExtraImages/20200917035143_Best-5-bikes-under-Rs-1.5-lakh.jpg"
                alt="Dealer 1"
                className="w-24 h-24 rounded-full mb-4 object-cover"
              />
              <h3 className="text-xl font-bold mb-2">Car Breez</h3>
              <p className="text-gray-700">
                Specializes in luxury vehicles with a focus on customer
                satisfaction.
              </p>
            </div>

            <div className="flex flex-col items-center text-center p-6 border border-gray-200 rounded-lg shadow-md">
              <img
                src="https://img.freepik.com/premium-photo/biker-modern-helmet_433905-13958.jpg"
                alt="Dealer 2"
                className="w-24 h-24 rounded-full mb-4 object-cover"
              />
              <h3 className="text-xl font-bold mb-2">Modern Biker</h3>
              <p className="text-gray-700">
                Known for exceptional service and a wide range of vehicles.
              </p>
            </div>

            <div className="flex flex-col items-center text-center p-6 border border-gray-200 rounded-lg shadow-md">
              <img
                src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/cddfa585-0197-4c1c-a6ba-b75632f25625/dg6di6g-51bfc7bb-40b3-4011-a906-184fd83019ff.jpg/v1/fill/w_1920,h_1904,q_75,strp/modern_bike_new__concept_art_of_by_exclusiveartmaker193_dg6di6g-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2NkZGZhNTg1LTAxOTctNGMxYy1hNmJhLWI3NTYzMmYyNTYyNVwvZGc2ZGk2Zy01MWJmYzdiYi00MGIzLTQwMTEtYTkwNi0xODRmZDgzMDE5ZmYuanBnIiwiaGVpZ2h0IjoiPD0xOTA0Iiwid2lkdGgiOiI8PTE5MjAifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uud2F0ZXJtYXJrIl0sIndtayI6eyJwYXRoIjoiXC93bVwvY2RkZmE1ODUtMDE5Ny00YzFjLWE2YmEtYjc1NjMyZjI1NjI1XC9leGNsdXNpdmVhcnRtYWtlcjE5My00LnBuZyIsIm9wYWNpdHkiOjk1LCJwcm9wb3J0aW9ucyI6MC40NSwiZ3Jhdml0eSI6ImNlbnRlciJ9fQ.UBuJVyaYtHp9_q4fdaCLv_DCAHKOjtSoMJQMgWm5IJw"
                alt="Dealer 3"
                className="w-24 h-24 rounded-full mb-4 object-cover"
              />
              <h3 className="text-xl font-bold mb-2">Super Bikes</h3>
              <p className="text-gray-700">
                A trusted name in the industry with years of experience.
              </p>
            </div>
          </div>
        </section>

        {/* Added Section */}
        <section className="w-full">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-6">
            Our Branches
          </h2>
          <Branches />
        </section>

        {/* 4th Section: Team Members */}
        <section className="w-full">
          <h2 className="text-3xl md:text-4xl font-bold text-center">
            Meet Our Team
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="flex flex-col items-center text-center p-6">
              <img
                src="https://media.istockphoto.com/id/1225695368/photo/young-indian-businessman-stock-photo.jpg?s=612x612&w=0&k=20&c=8-UwxzWvZDEDVDSboLCtWQx0ulHii17uiuWHro-S5EM="
                alt="Team Member 1"
                className="w-auto h-52 rounded-lg mb-4 object-cover"
              />
              <h3 className="text-xl font-bold mb-2">Santosh Kumar Sahoo</h3>
              <p className="text-gray-700">CEO</p>
            </div>

            <div className="flex flex-col items-center text-center p-6">
              <img
                src="https://media.istockphoto.com/id/1180241164/photo/portrait-of-businessman-stock-photo.jpg?s=612x612&w=0&k=20&c=r6hijxZo5JKuHSiRke7yRp-77gUu1ShX7rfp48Q87wM="
                alt="Team Member 2"
                className="w-auto h-52 rounded-lg mb-4 object-cover"
              />
              <h3 className="text-xl font-bold mb-2">Hemant Kumar Sahoo</h3>
              <p className="text-gray-700">Chairman</p>
            </div>

            <div className="flex flex-col items-center text-center p-6">
              <img
                src="https://media.istockphoto.com/id/1034955910/photo/man-standing-in-front-of-office-stock-image.jpg?s=612x612&w=0&k=20&c=f-GpD74VoAxe2BaUgT2ruumfhgJ7q1W-L6YTIVb21gI="
                alt="Team Member 3"
                className="w-auto h-52 rounded-lg mb-4 object-cover"
              />
              <h3 className="text-xl font-bold mb-2">Santosh Kumar Sahoo</h3>
              <p className="text-gray-700">Managing Director</p>
            </div>

            <div className="flex flex-col items-center text-center p-6">
              <img
                src="https://media.istockphoto.com/id/840531052/photo/smiling-businessman-or-worker-standing-in-suit-near-office-building.jpg?s=612x612&w=0&k=20&c=15m58u4P5X-hznFRlhPCI-nGOdZBxqVktfXS1vS98yQ="
                alt="Team Member 4"
                className="w-auto h-52 rounded-lg mb-4 object-cover"
              />
              <h3 className="text-xl font-bold mb-2">Hemant Kumar Sahoo</h3>
              <p className="text-gray-700">Product Manager</p>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default About;
