import React from 'react';
import img1 from '../../Assets/Ellipse 1.png';
import img2 from '../../Assets/Ellipse 2.png';
import img3 from '../../Assets/Ellipse 3.png';
import img4 from '../../Assets/Ellipse 4.png';
import img5 from '../../Assets/Ellipse 5.png';
import backgroundImage from '../../Assets/bg.png';

const ServicesPlaces = () => {
    const places = [
        {
            "name": "Cuttack",
            "image": img1
        },
        {
            "name": "Bhubaneswar",
            "image": img2
        },
        {
            "name": "Puri",
            "image": img3
        },
        {
            "name": "Balasore",
            "image": img4
        },
        {
            "name": "Paradeep",
            "image": img5
        }
    ];

    return (
        <div 
            className='w-full px-6 md:px-10 py-6 mb-6 bg-cover bg-center bg-no-repeat' 
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className='flex justify-center'>
                <span className='uppercase text-[#D01818] text-xl font-semibold tracking-wide border-b-2 border-dotted border-[#D01818]'>
                    Services
                </span>
            </div>
            <div className='text-center text-3xl md:text-4xl font-extrabold tracking-wider my-4 text-[#0D1637]'>
                Covered Cities
            </div>
            <div className='w-full flex items-center md:justify-between justify-center flex-wrap gap-8'>
                {places.map((place, index) => (
                    <div key={index} className='flex flex-col items-center mx-4 my-2'>
                        <img
                            src={place.image}
                            alt={place.name}
                            className="h-44 w-auto object-contain mb-2"
                        />
                        <p className='text-lg font-semibold text-[#0D1637]'>{place.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ServicesPlaces;
