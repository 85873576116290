import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../Assets/logo.png';
import InquiryModal from '../Modals/InquiryModal';

const MainNav = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    const isActive = (path) => location.pathname === path;

    return (
        <React.Fragment>
            <InquiryModal
                isOpen={isModalOpen}
                onClose={closeModal}
            />

            <div className="text-gray-800 bg-white shadow-lg">
                <div className="w-full px-6 md:px-10">
                    <div className="flex justify-between items-center py-1">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <img src={logo} alt="Logo" className="h-16 md:h-20 w-auto" />
                        </div>

                        <div className="md:hidden flex items-center">
                            <button
                                onClick={toggleSidebar}
                                className="text-gray-800 focus:outline-none"
                            >
                                {isSidebarOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                            </button>
                        </div>

                        <div className="hidden md:flex space-x-10">
                            <div
                                onClick={() => handleNavigation("/")}
                                className={`text-base font-bold cursor-pointer tracking-wide ${isActive("/") ? "text-[#810000]" : "text-gray-600 hover:text-[#810000]"
                                    }`}>
                                Home
                            </div>

                            <div
                                onClick={() => handleNavigation("/about")}
                                className={`text-base font-bold cursor-pointer tracking-wide ${isActive("/about") ? "text-[#810000]" : "text-gray-600 hover:text-[#810000]"
                                    }`}>
                                About
                            </div>

                            <div
                                onClick={() => handleNavigation("/contact")}
                                className={`text-base font-bold cursor-pointer tracking-wide ${isActive("/contact") ? "text-[#810000]" : "text-gray-600 hover:text-[#810000]"
                                    }`}>
                                Contact
                            </div>

                            <div
                                onClick={() => handleNavigation("/products")}
                                className={`text-base font-bold cursor-pointer tracking-wide ${isActive("/products") ? "text-[#810000]" : "text-gray-600 hover:text-[#810000]"
                                    }`}>
                                Products
                            </div>
                        </div>

                        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                            <button
                                onClick={openModal}
                                className="tracking-wide ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#1a1a26] hover:bg-[#810000] transition-transform duration-300 ease-in-out">
                                Inquiry
                            </button>
                        </div>
                    </div>
                </div>

                {isSidebarOpen && (
                    <div
                        className="md:hidden fixed inset-0 bg-gray-900 bg-opacity-50 z-50"
                        onClick={toggleSidebar}>
                        <div
                            className="absolute top-0 right-0 w-3/4 h-full bg-white shadow-lg"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="flex justify-center">
                                <img
                                    onClick={() => handleNavigation("/")}
                                    src={logo}
                                    alt="Logo"
                                    className="h-16 w-auto" />
                            </div>
                            <div className="flex flex-col p-5 space-y-5">
                                <div
                                    onClick={() => handleNavigation("/about")}
                                    className={`text-base font-medium ${isActive("/about") ? "text-[#810000]" : "text-gray-800 hover:text-gray-900"
                                        }`}>
                                    About
                                </div>

                                {/* <div
                                onClick={() => handleNavigation("/")}
                                className={`text-base font-medium ${isActive("/") ? "text-[#810000]" : "text-gray-800 hover:text-gray-900"
                                    }`}>
                                HHoommee
                            </div> */}

                                {/* <div
                                onClick={() => handleNavigation("/")}
                                className={`text-base font-medium ${isActive("/") ? "text-[#810000]" : "text-gray-800 hover:text-gray-900"
                                    }`}>
                                Home
                            </div> */}

                                <div
                                    onClick={() => handleNavigation("/products")}
                                    className={`text-base font-medium ${isActive("/products") ? "text-[#810000]" : "text-gray-800 hover:text-gray-900"
                                        }`}>
                                    Products
                                </div>

                                <div
                                    onClick={() => handleNavigation("/contact")}
                                    className={`text-base font-medium ${isActive("/contact") ? "text-[#810000]" : "text-gray-800 hover:text-gray-900"
                                        }`}>
                                    Contact
                                </div>

                                <button
                                    onClick={openModal}
                                    className="tracking-wide mt-4 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#1a1a26]">
                                    Inquiry
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default MainNav;