import React, { useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

// Testimonial data including images
const testimonials = [
    {
        id: 1,
        content: "I purchased a karizma bike with just 33k..very well pricing and staffs are also friendly!",
        author: "Abhijit Panda",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIhDVAzf1oyxBPKiQ2w8WzSVUV0eluUzUWVg&s",
    },
    {
        id: 2,
        content: "Archita motors Odisha best dealer two wheeler old seller!",
        author: "Sumanta Prusty",
        image: "https://img.freepik.com/free-photo/stylish-handsome-indian-man-tshirt-pastel-wall_496169-1571.jpg",
    },
    {
        id: 3,
        content: "I can't recommend this enough. Fantastic!",
        author: "Daya Nayak",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjtJqiPNplZ_hwP_fXLOLxBeQ-yqxIxVsHPQ&s",
    },
    {
        id: 4,
        content: "Excellent service and great pricing. Highly recommended!",
        author: "Sasmita Satapathy",
        image: "https://img.freepik.com/free-photo/portrait-confident-young-man_171337-4501.jpg",
    },
];

// Array of gradient background colors
const gradientColors = [
    "bg-gradient-to-r from-stone-300 to-stone-700",
    "bg-gradient-to-r from-gray-300 to-gray-700",
    "bg-gradient-to-r from-slate-300 to-slate-700",
];

const Testimonials = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? Math.max(testimonials.length - 2, 0) : prevIndex - 2
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex >= testimonials.length - 2 ? 0 : prevIndex + 2
        );
    };

    const displayedTestimonials = testimonials.slice(currentIndex, currentIndex + 2);

    return (
        <div className="flex flex-col items-center justify-center w-full px-4 md:px-10 mb-6">
            <div className='flex justify-center'>
                <span className='uppercase text-[#D01818] text-lg md:text-xl font-semibold tracking-wide border-b-2 border-dotted border-[#D01818]'>
                    Our Testimonials
                </span>
            </div>
            <div className='text-center text-2xl md:text-4xl font-extrabold tracking-wider my-4 text-[#0D1637]'>
                What Our Clients Say
            </div>

            <div className="flex items-center justify-between w-full space-x-4 md:space-x-8">
                <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={handlePrevious}
                >
                    <IoIosArrowBack size={28} />
                </button>
                <div className="flex flex-1 flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 justify-center">
                    {displayedTestimonials.map((testimonial, index) => (
                        <div
                            key={testimonial.id}
                            className={`flex-1 w-full md:w-1/2 flex flex-col items-center text-center shadow-md rounded-lg p-4 md:p-6 ${gradientColors[(currentIndex + index) % gradientColors.length]}`}
                        >
                            <p className="text-sm md:text-lg text-white tracking-wide">
                                {testimonial.content}
                            </p>

                            <img
                                src={testimonial.image}
                                alt={testimonial.author}
                                className="w-14 h-14 md:w-16 md:h-16 rounded-full mt-4 object-cover"
                            />

                            <p className="mt-4 tracking-wider font-bold text-lg md:text-xl text-[#D01818]">
                                - {testimonial.author}
                            </p>
                        </div>
                    ))}
                </div>
                <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={handleNext}
                >
                    <IoIosArrowForward size={28} />
                </button>
            </div>
        </div>
    );
};

export default Testimonials;
