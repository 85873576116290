import React from "react";
import bbsrBrnch from "../../Assets/bhubaneswarBranch.jpg";
import jajprBrnch from "../../Assets/Jajpurbranch.jpg";
import nialiBrnch from "../../Assets/NialiBranch.jpg";

const branches = [
  {
    name: "Bhubaneswar Branch",
    address:
      "Puri Bypass, plot no - 148, Pandra, Koradakanta, Bhubaneswar, Khordha - 751025, Odisha",
    phoneNumbers: ["9438572662", "7978272696"],
    managingDirector: "Hemant Kumar Sahoo",
    imageUrl: bbsrBrnch, // Replace with actual image URL
  },
  {
    name: "Jajpur Branch",
    address:
      "Plot no. - 1012, Near DU Public School, Chandikhol, Jajpur - 755044",
    phoneNumbers: ["9861484493"],
    managingDirector: "Santosh Kumar Sahoo",
    imageUrl: jajprBrnch,
  },
  {
    name: "Niali Branch",
    address: "Plot no. - 1384, Bangalisahi Chhak, Niali, Cuttack - 754004",
    phoneNumbers: ["7978272696", "8763081352"],
    managingDirector: "Hemant Kumar Sahoo",
    imageUrl: nialiBrnch,
  },
];

const BranchCard = ({
  name,
  address,
  phoneNumbers,
  managingDirector,
  imageUrl,
}) => {
  return (
    <div className="rounded-lg overflow-hidden shadow-lg bg-white p-6">
      <img
        className="w-full h-64 object-cover rounded-lg"
        src={imageUrl}
        alt={`${name} Image`}
      />
      <div className="mt-4">
        <h2 className="font-bold text-xl mb-2">{name}</h2>
        <p className="text-gray-700 text-base mb-2">
          <strong>Address:</strong> {address}
        </p>
        <p className="text-gray-700 text-base mb-2">
          <strong>Phone no:</strong> {phoneNumbers.join(", ")}
        </p>
        <p className="text-gray-700 text-base">
          <strong>Managing Director:</strong> {managingDirector}
        </p>
      </div>
    </div>
  );
};

const Branches = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
      {branches.map((branch, index) => (
        <BranchCard key={index} {...branch} />
      ))}
    </div>
  );
};

export default Branches;
