import React, { useState } from 'react';
import axios from 'axios';

const InquiryModal = ({ isOpen, onClose }) => {
    const initData = {
        name: '',
        phone: '',
        address: '',
        email: '',
        message: ''
    };

    const [formData, setFormData] = useState(initData);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = JSON.stringify({
            "name": formData?.name,
            "phone": formData?.phone,
            "address": formData?.address,
            "email": formData?.email,
            "message": formData?.message
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://archita-motors.onrender.com/archita-motors-api/inquiry/create-inquiry',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        setIsLoading(true);
        axios.request(config)
            .then((response) => {
                console.log("res", response.data);
                setFormData(initData);
                setIsLoading(false);
                onClose();
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>
            <div className="relative bg-white p-4 rounded-lg shadow-lg max-w-xs w-full sm:w-9/12 md:w-8/12 lg:w-6/12 xl:w-4/12 z-10 mx-4 my-8 max-h-screen overflow-y-auto">
                <h2 className="text-xl font-semibold mb-4">Inquiry Form</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Name"
                            className="w-full px-3 py-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Phone"
                            className="w-full px-3 py-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="text"
                            id="address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            placeholder="Address"
                            className="w-full px-3 py-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Email (Optional)"
                            className="w-full px-3 py-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Message"
                            className="w-full px-3 py-2 border border-gray-300 rounded"
                            rows="3"
                        />
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="button"
                            onClick={() => {
                                onClose();
                                setFormData(initData);
                            }}
                            className="px-4 py-2 bg-transparent text-[#1a1a26] border border-[#1a1a26] font-bold tracking-wide text-base rounded-md"
                        >
                            Cancel
                        </button>

                        {
                            isLoading
                                ?
                                <button
                                    className="ml-4 whitespace-nowrap inline-flex items-center justify-center px-4 py-0 border border-transparent rounded-md shadow-sm text-2xl font-medium text-white bg-[#810000]"
                                >
                                    <span className='animate-spin'>
                                        ⚙️
                                    </span>
                                </button>
                                :
                                <button
                                    type="submit"
                                    className="tracking-wide ml-4 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#1a1a26] hover:bg-[#810000] transition-transform duration-300 ease-in-out"
                                >
                                    Submit
                                </button>
                        }
                    </div>
                </form>
            </div>
        </div>
    );
};

export default InquiryModal;
