import React from 'react';
import Carousel from '../../Components/Home/Carousel';
import BrandCards from '../../Components/Home/BrandCards';
import frame1 from "../../Assets/banner1.png";
import frame2 from "../../Assets/smallBanner1.png";
import HomeAbout from '../../Components/Home/HomeAbout';
import RecentlyAddedBikes from '../../Components/Home/RecentlyAddedBikes';
import WhyChoose from '../../Components/Home/WhyChoose';
import Counter from '../../Components/Home/Counters';
import ServicesPlaces from '../../Components/Home/ServicesPlaces';
import backgroundImage from '../../Assets/Background.png';
import { BiSolidPhoneCall } from "react-icons/bi";
import Testimonials from '../../Components/Home/Testimonials';

const Home = () => {
  return (
    <React.Fragment>
      <Carousel />
      <BrandCards />
      <RecentlyAddedBikes />
      <React.Fragment>
        <img
          src={frame1}
          alt="frame"
          className="w-full hidden md:block" />

        <div className='block md:hidden bg-[#080404] p-6 md:px-10'>
          <img
            src={frame2}
            alt="frame"
            className="w-full" />
        </div>
      </React.Fragment>
      <HomeAbout />
      <WhyChoose />
      
      <div className='px-6 md:px-10 py-6'>
        <Counter />
      </div>

      <ServicesPlaces />
      <div
        style={{ backgroundImage: `url(${backgroundImage})` }}
        className='rounded-md mb-6 mx-6 md:mx-10 px-6 md:px-10 py-6 bg-cover bg-center bg-no-repeat flex md:flex-row flex-col justify-between items-center'>
        <div className='text-3xl md:text-4xl font-extrabold tracking-wider my-4 text-[#ffffff]'>
          Find Used Bikes<br /> & Scooter
        </div>

        <div className='flex justify-center items-center flex-wrap text-white gap-4'>
          <div className='border border-white p-2 rounded-full'>
            <BiSolidPhoneCall size={22} />
          </div>
          <div className='text-center tracking-wide'>
            <div>
              Phone Number
            </div>
            <div>
              +91-7978272696
            </div>
          </div>
          <button className='p-2 bg-white text-black font-semibold rounded-md tracking-wide'>
            Get a Quote
          </button>
        </div>
      </div>
      <Testimonials />
    </React.Fragment>
  )
}

export default Home
