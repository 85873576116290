import React, { useEffect, useState } from "react";
import { BsFillFuelPumpDieselFill } from "react-icons/bs";
import { IoMdSpeedometer } from "react-icons/io";
import { GiWeight } from "react-icons/gi";
import { TbEngine } from "react-icons/tb";
import { FaArrowRight } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const RecentlyAddedBikes = () => {
  const [bikes, setBikes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchBikes();
  }, []);

  const fetchBikes = () => {
    setIsLoading(true);
    axios
      .get(
        "https://archita-motors.onrender.com/archita-motors-api/bikes/get-all-bikes"
      )
      .then((response) => {
        const Data = response.data.slice(0, 6);
        setBikes(
          Data.filter((each, index) => {
            return each.primaryDetails.bikeStatus === "InShowroom";
          })
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching bikes:", error);
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <div className="mx-auto px-6 md:px-10 my-6">
        <div className="flex justify-start">
          <span className="uppercase text-[#D01818] text-xl font-semibold tracking-wide border-b-2 border-dotted border-[#D01818]">
            Recently Added Bikes
          </span>
        </div>
        <div className="text-start text-3xl md:text-4xl font-extrabold tracking-wider my-4 text-[#0D1637]">
          Check Out Our Bike Collection
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {[1, 2, 3, 4, 5, 6].map((index) => (
            <div
              key={index}
              className="relative bg-white rounded-lg shadow-md overflow-hidden flex flex-col animate-pulse"
            >
              <div className="w-full h-64 bg-gray-300"></div>
              <div className="p-4 flex-1 flex flex-col tracking-wide">
                <div className="bg-gray-300 h-6 w-3/4 mb-2"></div>
                <div className="bg-gray-300 h-4 w-1/2 mb-4"></div>
                <div className="flex justify-between mb-4">
                  <div className="flex items-center space-x-1">
                    <div className="bg-gray-300 h-4 w-8"></div>
                  </div>
                  <div className="flex items-center space-x-1">
                    <div className="bg-gray-300 h-4 w-8"></div>
                  </div>
                  <div className="flex items-center space-x-1">
                    <div className="bg-gray-300 h-4 w-8"></div>
                  </div>
                  <div className="flex items-center space-x-1">
                    <div className="bg-gray-300 h-4 w-8"></div>
                  </div>
                </div>
                <div className="bg-gray-300 h-4 w-3/4"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto px-6 md:px-10 my-6">
      <div className="flex justify-start">
        <span className="uppercase text-[#D01818] text-xl font-semibold tracking-wide border-b-2 border-dotted border-[#D01818]">
          Recently Added Bikes
        </span>
      </div>
      <div className="text-start text-3xl md:text-4xl font-extrabold tracking-wider my-4 text-[#0D1637]">
        Check Out Our Bike Collection
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {bikes.map((bike) => (
          <BikeCard key={bike._id} bike={bike} />
        ))}
      </div>
    </div>
  );
};

export default RecentlyAddedBikes;

const BikeCard = ({ bike }) => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(`/bike-details/${path}`);
  };

  return (
    <div
      onClick={() => handleNavigation(bike?._id)}
      className="relative bg-white rounded-lg shadow-md overflow-hidden flex flex-col transition-transform duration-300 transform hover:scale-105 hover:shadow-lg cursor-pointer"
    >
      <img
        src={bike?.primaryDetails?.bikeImages[0]}
        alt="bike-pic"
        className="w-full h-64 object-cover"
      />
      <div className="absolute top-4 left-4 tracking-wide bg-[#D01818] text-white px-3 py-1 rounded-lg">
        Featured
      </div>
      <div className="absolute top-4 right-4 tracking-wide bg-[#1a1a26] text-white px-3 py-1 rounded-lg">
        {bike?.primaryDetails?.originalPurchaseDate?.substring(0, 4)}
      </div>
      <div className="p-4 flex-1 flex flex-col tracking-wide">
        <h2 className="text-lg font-bold text-[#0D1637]">
          {bike?.primaryDetails?.makeAndModel}
        </h2>
        <p className="text-[#D01818] my-2 font-semibold">
          ₹&nbsp;{bike?.primaryDetails?.secondSellingPrice}
        </p>

        <div className="flex justify-between tracking-wide mb-4">
          <div className="flex items-center space-x-1">
            <BsFillFuelPumpDieselFill
              color="#810000"
              className="text-[14px] md:text-[17px]"
            />
            <div>
              <div className="text-[9px] md:text-[12px] text-[#A2A4A8]">
                Fuel
              </div>
              <div className="text-[9px] md:text-[12px] text-[#0D1637]">
                {bike?.primaryDetails?.fuelType}
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-1">
            <IoMdSpeedometer
              color="#810000"
              className="text-[14px] md:text-[17px]"
            />
            <div>
              <div className="text-[9px] md:text-[12px] text-[#A2A4A8]">
                Mileage
              </div>
              <div className="text-[9px] md:text-[12px] text-[#0D1637]">60</div>
            </div>
          </div>
          <div className="flex items-center space-x-1">
            <GiWeight color="#810000" className="text-[14px] md:text-[17px]" />
            <div>
              <div className="text-[9px] md:text-[12px] text-[#A2A4A8]">
                Weight
              </div>
              <div className="text-[9px] md:text-[12px] text-[#0D1637]">
                110KG
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-1">
            <TbEngine color="#810000" className="text-[14px] md:text-[17px]" />
            <div>
              <div className="text-[9px] md:text-[12px] text-[#A2A4A8]">
                Engine
              </div>
              <div className="text-[9px] md:text-[12px] text-[#0D1637]">
                {bike?.primaryDetails?.engineDisplay}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="mt-2 inline-flex items-center justify-end gap-2 px-4 py-2">
          <span className="text-md text-[#0D1637] tracking-wide font-bold">
            View Details
          </span>
          <span>
            <FaArrowRight size={18} color="#810000" />
          </span>
        </div>
      </div>
    </div>
  );
};
