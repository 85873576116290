import React from 'react';
import PageHeader from '../../Components/Layout/PageHeader';

const Contact = () => {
  return (
    <React.Fragment>
      <PageHeader pageName="Contact Us" />

      <div className="flex flex-col items-center px-6 md:px-10 py-12">
        {/* Page Description */}
        <section className="w-full max-w-3xl text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-[#0D1637]">
            Get in Touch with Us
            </h2>
          <p className="text-lg md:text-xl text-gray-700">
            We're here to help and answer any question you might have. We look forward to hearing from you!
            Reach out to us through any of the following contact details or fill out the form below, and we'll get back to you as soon as possible.
          </p>
        </section>

        {/* Contact Details */}
        <section className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          <div className="flex flex-col items-center text-center">
            <h3 className="text-xl font-bold mb-2">📍 Address</h3>
            <p className="text-gray-700">
              123 Street Name, City, State, 12345
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <h3 className="text-xl font-bold mb-2">📞 Phone</h3>
            <p className="text-gray-700">
              +1 234 567 890
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <h3 className="text-xl font-bold mb-2">✉️ Email</h3>
            <p className="text-gray-700">
              contact@webbocket.com
            </p>
          </div>
        </section>

        {/* Contact Form with Image */}
        <section className="w-full grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="hidden lg:block">
            <img
              src="https://www.motorcyclistonline.com/resizer/dREnNeGeXJ8Q-sU7eXxNHkHXJxY=/arc-photo-octane/arc3-prod/public/65HEDIOPAJH3NETELKAYYZGBIU.jpg"
              alt="Contact Us"
              className="w-full h-full object-cover rounded-lg shadow-md"
            />
          </div>
          <div>
            <form className="space-y-6">
              <div className='text-center text-3xl md:text-4xl font-extrabold tracking-wider my-4 text-[#D01818]'>
                CONTACT FORM
              </div>
              <div className="flex flex-col md:flex-row md:space-x-6">
                <div className="flex-1 mb-6 md:mb-0">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Your Name
                  </label>
                  <input
                    type="text"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-[#D01818] focus:border-[#D01818]"
                    placeholder="Enter your name"
                    required
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Your Email
                  </label>
                  <input
                    type="email"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-[#D01818] focus:border-[#D01818]"
                    placeholder="Enter your email"
                    required
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Subject
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-[#D01818] focus:border-[#D01818]"
                  placeholder="Enter subject"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Your Message
                </label>
                <textarea
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-[#D01818] focus:border-[#D01818]"
                  rows="6"
                  placeholder="Write your message"
                  required
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full px-4 py-2 bg-[#D01818] text-white font-semibold rounded-lg shadow-md hover:bg-[#A01414] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#D01818]"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Contact;
