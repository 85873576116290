import React from "react";
import { useNavigate } from "react-router-dom";
import { IoMdMail } from "react-icons/io";
import { IoLocation } from "react-icons/io5";
import { BiSolidPhoneCall } from "react-icons/bi";
import logo from "../../Assets/Mask group.png";

const Footer = () => {
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();

    const handleNavigation = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    return (
        <React.Fragment>
            <footer className="bg-[#090A0A] text-white py-10 px-6 md:px-10 ">
                <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
                    {/* First Column: Logo and Description */}
                    <div className="space-y-3 text-center md:text-left">
                        <div
                            className="flex justify-center md:justify-start"
                        >
                            <img src={logo} alt="Logo" className="w-36" />
                        </div>
                        <p
                            className="text-sm md:text-base tracking-wider text-justify md:text-left"
                        >
                            Dealership has multiple locations
                            throughout the city selling new and used
                            bikes.
                        </p>
                    </div>

                    {/* Second Column: Quick Links */}
                    <div
                        className="space-y-3 text-center md:text-left"
                    >
                        <h5 className="text-lg font-bold mb-1">
                            <span className="pb-1 border-b-2 border-[#C0C0C0] tracking-wider">
                                Quick Links
                            </span>
                        </h5>
                        <div className="space-y-2 tracking-wider">
                            <div
                                onClick={() => handleNavigation("/")}
                                className="text-[#C0C0C0] cursor-pointer hover:text-white transition"
                            >
                                Home
                            </div>
                            <div
                                onClick={() => handleNavigation("/products")}
                                className="text-[#C0C0C0] cursor-pointer hover:text-white transition"
                            >
                                Our Bikes
                            </div>
                            <div
                                onClick={() => handleNavigation("/about")}
                                className="text-[#C0C0C0] cursor-pointer hover:text-white transition"
                            >
                                About Us
                            </div>
                            <div
                                onClick={() => handleNavigation("/contact")}
                                className="text-[#C0C0C0] cursor-pointer hover:text-white transition"
                            >
                                Contact Us
                            </div>
                        </div>
                    </div>

                    {/* Third Column: Contact Details */}
                    <div
                        className="space-y-3 text-center md:text-left"
                    >
                        <h5 className="text-lg font-bold mb-1">
                            <span className="pb-1 border-b-2 border-[#C0C0C0] tracking-wider">
                                Contact Us
                            </span>
                        </h5>
                        <div className="space-y-2 tracking-wider text-sm">
                            <div className="flex items-center justify-center md:justify-start">
                                <BiSolidPhoneCall color="white" size={21} />
                                <span className="ml-2">+91-7978272696, 9090939687 </span>
                            </div>
                            <div className="flex items-center justify-center md:justify-start">
                                <IoMdMail color="white" size={20} />
                                <span className="ml-2">needhelp@architamotora.com</span>
                            </div>
                            <div className="flex items-center justify-center md:justify-start">
                                <IoLocation color="white" size={28} />
                                <span className="ml-2">
                                Plot No- 1384, Bangali Sahi Chhak, Niali, Cuttack - 754004
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="bg-[#202020] text-white py-2 text-center text-sm">
                <div className="flex flex-col md:flex-row justify-center items-center">
                    <div className="mb-2 md:mb-0 md:mr-4">
                        Privacy Policy | Terms & Conditions
                    </div>
                    <div>
                        <span>© {currentYear}, </span>
                        <span className="text-yellow-600 font-semibold">
                            Archita Motors &nbsp;|| &nbsp;
                        </span>
                        <span
                            className="text-[#fe8740] cursor-pointer"
                            onClick={() => window.open("https://webbocket.com/")}
                        >
                            Web_Bocket PVT LTD.
                        </span>{" "}
                        All Rights Reserved.
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Footer;










// import React from 'react'

// const Footer = () => {
//     const currentYear = new Date().getFullYear();

//     return (
//         <React.Fragment>
//             <footer className="footer bg-gray-800 shadow text-white px-5 py-4">
//                 <div className="container mx-auto">
//                     <div className="flex justify-between">
//                         <div className="md:w-1/2 text-center md:text-left mb-4 md:mb-0">
//                             {currentYear} © <span className=''>P & M</span>.
//                         </div>
//                         <div className="md:w-1/2 text-center md:text-right">
//                             <div className="text-sm d-none d-sm-block">
//                                 Design & Develop by
//                                 <a href="#" className="ms-1 underline">
//                                     Web-Bocket
//                                 </a>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </footer>
//         </React.Fragment>
//     )
// }

// export default Footer
