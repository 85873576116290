import React from 'react';
import backgroundImage from '../../Assets/pageBanner.png';

const PageHeader = ({ pageName }) => {
    return (
        <header 
            className="relative w-full h-60 flex items-center justify-center bg-cover bg-center text-white"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="relative z-10 text-center px-6 md:px-10">
                <h1 className="text-3xl md:text-5xl tracking-wider font-bold">
                    {pageName}
                </h1>
            </div>
        </header>
    );
};

export default PageHeader;
