import React from 'react';
import buyBike from "../../Assets/buyBikes.svg";
import sellBike from "../../Assets/sellBikes.svg";
import repairBike from "../../Assets/repairBike.svg";

const cards = [
    {
        id: 1,
        name: 'Buy a Bike',
        description: "Discover unbeatable deals and buy bikes that fit your lifestyle and budget perfectly.",
        image: buyBike
    },
    {
        id: 2,
        name: 'Sell a Bike',
        description: 'Explore our selection of top-quality bikes for sale and hit the road with style and performance.',
        image: sellBike
    },
    {
        id: 3,
        name: 'Repair a Bike',
        description: 'Expertly restoring bikes to their prime, ensuring every ride is smooth, safe, and enjoyable.',
        image: repairBike
    },
];

const Card = ({ card }) => (
    <div className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col items-center tracking-wide p-6">
        <img
            src={card.image}
            alt={card.name}
            className="h-36 w-auto object-contain mb-4"
        />
        <h3 className="text-xl font-bold text-gray-800">
            {card.name}
        </h3>
        <p className="text-gray-600 text-center mt-2">
            {card.description}
        </p>
    </div>
);

const WhyChoose = () => (
    <div className="px-6 md:px-10 mb-6">
        <div className='flex justify-center'>
            <span className='uppercase text-[#D01818] text-xl font-semibold tracking-wide border-b-2 border-dotted border-[#D01818]'>
                Services
            </span>
        </div>
        <div className='text-center text-3xl md:text-4xl font-extrabold tracking-wider my-4 text-[#0D1637]'>
            Why Choose Us ..?
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {cards.map((card) => (
                <Card key={card.id} card={card} />
            ))}
        </div>
    </div>
);

export default WhyChoose;
