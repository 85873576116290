import React from "react";
import brand1 from "../../Assets/harley.png";
import brand2 from "../../Assets/kawa.png";
import brand3 from "../../Assets/re.png";
import brand4 from "../../Assets/sujuki.png";
import brand5 from "../../Assets/ktm.png";
import brand6 from "../../Assets/tvs.png";
import brand7 from "../../Assets/bajaj.png";
import brand8 from "../../Assets/yamaha.png";
import brand9 from "../../Assets/hero.png";
import brand10 from "../../Assets/honda.png";

const BrandCards = () => {
  // Sample data for the cards
  const cardData = [
    { id: 1, name: "Harley Davidson", logo: brand1 },
    { id: 2, name: "Kawasaki", logo: brand2 },
    { id: 3, name: "Royal Enfield", logo: brand3 },
    { id: 4, name: "Suzuki", logo: brand4 },
    { id: 5, name: "KTM", logo: brand5 },
    { id: 6, name: "TVS", logo: brand6 },
    { id: 7, name: "Bajaj", logo: brand7 },
    { id: 8, name: "Yamaha", logo: brand8 },
    { id: 9, name: "Hero", logo: brand9 },
    { id: 10, name: "Honda", logo: brand10 },
  ];

  return (
    <div className="px-6 md:px-10 py-6">
      <div className="flex justify-center">
        <span className="uppercase text-[#D01818] text-xl font-semibold tracking-wide border-b-2 border-dotted border-[#D01818]">
          Brand Category
        </span>
      </div>
      <div className="text-center text-3xl md:text-4xl font-extrabold tracking-wider my-4 text-[#0D1637]">
        Browse By Makes
      </div>
      <div className="grid grid-cols-2 md:grid-cols-5 gap-6">
        {cardData.map((card) => (
          <div
            key={card.id}
            className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center"
          >
            <img
              src={card.logo}
              alt={card.name}
              className="h-20 w-auto object-contain mb-2"
            />
            <h3 className="text-lg font-bold text-[#141414] text-center tracking-wide">
              {card.name}
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandCards;
